import { isEmpty } from 'lodash';
import * as Objects from 'Helper/object/Object';
import * as Html from 'Helper/browser/Html';
import * as Strings from 'Helper/string/String';
import * as Cookie from 'Helper/browser/Cookie';
import * as State from 'Domain/version/web/core/State';
import * as Provider from 'Domain/version/web/core/Provider';
import LazyLoad from 'Domain/version/web/feature/LazyLoad';
import TimerRefresh from 'Domain/version/web/feature/TimerRefresh';
import Style from 'Domain/version/web/feature/Style';
import Placeholder from 'Domain/version/web/feature/Placeholder';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'Helper/metrics/NewRelicError';
import { NewRelicMetric } from 'Helper/metrics/NewRelicMetric';
import * as Interstitial from 'Domain/version/web/provider/googleads/Interstitial';
import { Observers } from 'Domain/version/web/config/Observers';
import { Slot } from 'Types/Slot';
import { Providers } from 'Domain/version/web/config/Providers';
import { Features } from 'Domain/version/web/core/FeatureContainer';

export const getInnerId = (containerId) => `${containerId}-inner`;

export const getKeyById = (settings, pageId, containerId, slotId) => {
  if (settings == null) return null;
  const slotKey = `${pageId}#${containerId}#${slotId}`;
  const groupKey = Object.keys(settings).find((setting) => setting.split(',').includes(slotKey));

  const settingsSlot = groupKey ? settings[groupKey] : null;
  if (groupKey && settingsSlot) {
    return Strings.hashCode(groupKey);
  }
  const settingsPage = settings?._[pageId];
  if (settingsPage) {
    return Strings.hashCode(pageId);
  }
  const settingsDefault = settings?._?._;
  if (settingsDefault) {
    return Strings.hashCode('_');
  }
  return null;
};

export const getShouldCreate = (slot: Slot) => {
  if (!slot.interstitial || isEmpty(slot.interstitial) || !slot.interstitial.enabled) {
    return true;
  }

  const slotKey = `${State.getPageId()}#${slot.interstitial.targetPage}`;
  const validFrequencyCap = Interstitial?.validFrequencyCap?.(slot, slotKey);
  if (!validFrequencyCap) {
    newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_INVALID_FREQUENCY_CAP, {
      currentDate: Date.now(),
      pageId: State.getPageId(),
      containerId: slot.containerId,
      slotId: slot.id,
    });
  }
  return validFrequencyCap;
};

export const setParams = (containerId: string, params: Record<string, unknown> = {}) => {
  /* eslint-disable no-param-reassign */
  params.dfp_user_id = State.getUserId();
  params.sessionLong = State.getSessionLong();
  params.page = State.getPageId();
  params.container = containerId;
  params.featureFlags = Cookie.get('laquesisff')?.split('#') || [];
  params.abTest = Cookie.get('laquesis')?.split('#') || [];
  /* eslint-enable no-param-reassign */
};

const groupByProvider = (containerIds: string[] = []) => {
  const slotsGrouped = {};
  const slots = State.getSlots();
  for (const containerId of Object.keys(slots)) {
    if (!containerIds || containerIds.length === 0 || containerIds.includes(containerId)) {
      const slot = slots[containerId];
      if (slot) {
        const providerId = slot.provider;
        if (providerId) {
          if (!slotsGrouped[providerId]) slotsGrouped[providerId] = [];
          slotsGrouped[providerId].push(slot);
        }
      }
    }
  }
  return slotsGrouped as Record<Providers, Slot[]>;
};

const createInner = (container) => {
  console.info('[SLOTS][SLOT][CREATEINNER]', container);
  const innerId = getInnerId(container.id);

  Html.addClass(container, 'baxter-container');

  if (!Html.getElementById(innerId)) {
    Html.clearElement(container.id);
    Html.showElement(container.id);
    const inner = document.createElement('div');
    inner.id = innerId;

    Html.addClass(inner, 'baxter-inner');
    console.debug(`[SLOTS][SLOT][CREATEINNER] container.appendChild`, inner);
    container.appendChild(inner);
  }
};

const refresh = async (...containerIds) => {
  console.info('[SLOTS][SLOT][REFRESH]', containerIds);
  const groupedSlots = groupByProvider(containerIds);

  for (const providerId of Object.keys(groupedSlots)) {
    const slots = groupedSlots[providerId];

    if (Array.isArray(slots) && slots.length > 0) {
      const providerModule = Provider.getById(providerId as Providers);
      const providerRefresh = providerModule ? providerModule.refresh : null;

      if (providerRefresh) {
        let success;
        try {
          // eslint-disable-next-line no-await-in-loop
          success = await providerRefresh(slots);
        } catch (err) {
          console.error(`[SLOTS][SLOT][REFRESH]`, err);
          newRelicMetrics.reportError(NewRelicError.SLOT_PROVIDER_REFRESH_THROWN_ERROR, {
            message: (err as Error).message,
          });
        }
        slots.forEach((slot) => {
          if (TimerRefresh) TimerRefresh.startCountdown(State.getPageId(), slot.containerId, slot.id);
        });
        if (success) {
          console.debug('[SLOTS][SLOT][REFRESH] success', providerId, slots);
        } else {
          console.error(`[SLOTS][SLOT][REFRESH] failure ${providerId}`, slots);
          newRelicMetrics.reportError(NewRelicError.SLOT_PROVIDER_REFRESH_RETURNED_ERROR, {
            providerId,
            message: 'refresh failure',
          });
        }
      }
    }
  }
};

export const remove = async (...containerIds: string[]) => {
  console.info('[SLOTS][SLOT][REMOVE]', containerIds);
  const groupedSlots = groupByProvider(containerIds);
  for (const provider of Object.keys(groupedSlots)) {
    const providerId = provider as Providers;
    const slots = groupedSlots[providerId];
    console.debug('[SLOTS][SLOT][REMOVE] slots', slots);

    if (Array.isArray(slots) && slots.length > 0) {
      const providerSettings = Provider.getSettings(providerId);
      const providerModule = Provider.getById(providerId);
      const providerRemove = providerModule ? providerModule.remove : null;

      if (providerRemove) {
        console.debug('[SLOTS][SLOT][REMOVE] providerRemove', slots, providerSettings);
        let success;
        try {
          // eslint-disable-next-line no-await-in-loop
          success = await providerRemove(slots, providerSettings);
        } catch (err) {
          console.error('[SLOTS][SLOT][REMOVE]', err);
          newRelicMetrics.reportError(NewRelicError.SLOT_PROVIDER_REMOVE_THROWN_ERROR, {
            providerId,
            message: (err as Error).message,
          });
        }
        if (success) {
          console.debug('[SLOTS][SLOT][REMOVE] SUCCESS', slots);
          slots.forEach((slot) => {
            Features.forEach((feature) => {
              if (feature && feature.isEnabledForSlot(slot.pageId, slot.containerId, slot.id)) {
                feature.remove(slot);
              }
            });
            State.removeGeneralObserver(slot.containerId);
            State.removeElementObservers(slot.containerId);
            State.removeContainerTimers(slot.containerId);
            State.removeSlot(slot.containerId);
            Html.hideElement(getInnerId(slot.containerId));
            Style.removeClass(slot.pageId, slot.containerId, slot.id);
            Placeholder.apply(slot.containerId, slot.pageId, Objects.clone(State.getPageParams()));
          });
        } else {
          console.error('[SLOTS][SLOT][REMOVE] FAILURE');
          newRelicMetrics.reportError(NewRelicError.SLOT_PROVIDER_REMOVE_RETURNED_ERROR, { providerId });
        }
      }
    }
  }
};

const create = async (containerId, slotId, params) => {
  console.info('[SLOTS][SLOT][CREATE]', containerId, slotId, params);
  const pageId = State.getPageId();
  const slot = State.getSlot(containerId);

  const lazyLoadPreRequest = LazyLoad ? LazyLoad.isPreRequest(pageId, containerId, slotId) : false;
  if ((!slot.status.lazyLoad || slot.status.visible || lazyLoadPreRequest) && !slot.filled) {
    const providerId = slot.provider;
    const providerModule = Provider.getById(providerId);

    if (!providerModule) {
      console.error(`[SLOTS][SLOT][CREATE] ${containerId} ${slotId} PROVIDER MODULE NOT FOUND: ${providerId}`);
      newRelicMetrics.reportError(NewRelicError.SLOT_PROVIDER_MODULE_FOR_CREATE_NOT_FOUND, {
        pageId,
        containerId,
        slotId,
        params,
        providerId,
      });
      return;
    }

    if (!getShouldCreate(slot)) {
      console.debug(`[SLOTS][SLOT][CREATE] ${containerId} ${slotId} SKIPPING LOAD FOR`, slot);
      return;
    }
    let external;
    try {
      console.debug(
        `[SLOTS][SLOT][CREATE] container before create`,
        containerId,
        Html.getElementById(containerId),
        Html.getElementById(getInnerId(containerId))
      );
      external = await providerModule.create(slot);
      console.debug(`[SLOTS][SLOT][CREATE] ${containerId} ${slotId} slot.external = external`, external);
      slot.external = external;
      slot.status.created = true;
      console.debug(
        `[SLOTS][SLOT][CREATE] container after create`,
        containerId,
        Html.getElementById(containerId),
        Html.getElementById(getInnerId(containerId))
      );
    } catch (err) {
      console.error(`[SLOTS][SLOT][CREATE] ${containerId} ${slotId} slot.external = null`, err);
      newRelicMetrics.reportError(NewRelicError.SLOT_CREATE_ERROR, { message: (err as Error).message, providerId });
      slot.external = null;
      slot.status.created = false;
      Placeholder.apply(containerId, pageId, params);
    }
    globalThis.Baxter.slots[slot.id] = slot;
    if (TimerRefresh) TimerRefresh.startCountdown(pageId, containerId, slotId);
  } else if (LazyLoad && LazyLoad.isEventType(pageId, containerId, slotId)) {
    console.debug(`[SLOTS][SLOT][CREATE] ${containerId} ${slotId} LazyLoad.addSlotEventListener`);
    await LazyLoad.addSlotEventListener(pageId, containerId, slotId);
  }
};

export const setContainerObserver = (containerId, isLazyLoaded) => {
  if (isLazyLoaded) {
    const pageId = State.getPageId();
    const slot = State.getSlot(containerId);
    const lazyLoadSettings = globalThis.Baxter.context.configurationService.getSlotLazyLoad(
      pageId,
      containerId,
      slot.id
    );
    const distanceFromViewport = lazyLoadSettings.distanceFromViewport || 0;
    const rootMargin = `${distanceFromViewport}px 0px ${distanceFromViewport}px 0px`;
    State.addElementObserver(containerId, Observers.intersectionLazyLoad, {
      rootMargin,
    });
    State.removeGeneralObserver(containerId);
  } else {
    State.addGeneralObserver(containerId);
    State.removeElementObservers(containerId);
  }
};
const recentlySet = (date) => {
  if (!date) return false;
  const SECS = 1000 * 2;
  const secsAgo = Date.now() - SECS;

  return date > secsAgo;
};

export const set = async (containerId, params, skipContainerRecentlySetCheck) => {
  console.info('[SLOTS][SLOT][SET]', containerId, params, skipContainerRecentlySetCheck);
  console.debug(
    `[SLOTS][SLOT][SET] container`,
    containerId,
    Html.getElementById(containerId),
    Html.getElementById(getInnerId(containerId))
  );
  setParams(containerId, params);
  const pageId = State.getPageId();
  const NO_ADS_VARIANT = 'euads-dummy@dummy';
  if (params.abTest.includes(NO_ADS_VARIANT)) {
    console.debug(
      `[SLOTS][SLOT][SET] ${pageId} ${containerId} skipping ads beacuse of experiment variant`,
      NO_ADS_VARIANT
    );
    return;
  }
  const { slotForContainerFinder } = globalThis.Baxter.context;
  const slotId = slotForContainerFinder.find(containerId, params);
  if (!pageId || !containerId) {
    console.error(`[SLOTS][SLOT][SET] NO PAGE ID ${pageId} OR CONTAINER ID ${containerId}`);
    newRelicMetrics.reportError(NewRelicError.SLOT_NO_CONTAINER_ID_OR_PAGE_ID, { pageId, containerId, slotId, params });
    return;
  }
  if (!slotId) {
    console.debug(`[SLOTS][SLOT][SET] ${pageId} ${containerId} NO MATCHING SLOT ${slotId}`);
    return;
  }

  if (!skipContainerRecentlySetCheck && recentlySet(State.getContainerSet(containerId))) {
    console.error(`[SLOTS][SLOT][SET] ${containerId} CONTAINER SET TOO RECENTLY`);
    newRelicMetrics.reportError(NewRelicError.SLOT_CONTAINER_SET_TOO_RECENTLY, { pageId, containerId, slotId, params });
    return;
  }

  const container = Html.getElementById(containerId);
  const providerId = globalThis.Baxter.context.configurationService.getSlotProvider(pageId, containerId, slotId);
  const existingSlot = State.getSlot(containerId) || {};

  if (container && providerId) {
    console.debug(`[SLOTS][SLOT][SET] State.setContainerSet`, containerId);
    State.setContainerSet(containerId);
    createInner(container);

    const providerModule = Provider.getById(providerId);
    if (providerModule) {
      let newSlot: Partial<Slot> = providerModule.transform
        ? await providerModule.transform(pageId, containerId, slotId, params)
        : {};

      const isChanged = Objects.hasDiff({ id: slotId, params }, { id: existingSlot.id, params: existingSlot.params });
      const isLazyLoaded = LazyLoad && LazyLoad.isEnabledForSlot(pageId, containerId, slotId);
      const isPreRequested = LazyLoad && LazyLoad.isPreRequest(pageId, containerId, slotId);

      if (existingSlot.status?.created && !isChanged) {
        console.debug(`[SLOTS][SLOT][SET] refresh`, containerId);
        await refresh(containerId);
      } else {
        console.debug(`[SLOTS][SLOT][SET] remove`, containerId);
        await remove(containerId);

        newSlot.id = slotId;
        newSlot.pageId = pageId;
        newSlot.containerId = containerId;
        newSlot.innerId = getInnerId(containerId);
        newSlot.provider = providerId;
        newSlot.params = params;
        newSlot.status = {
          loaded: existingSlot.status?.loaded || false,
          visible: Html.isElementInViewport(containerId, 0),
          fold: null,
          lazyLoad: isLazyLoaded,
          refreshCount: existingSlot.status?.refreshCount || 0,
          refreshPending: isPreRequested || false,
        };
        Features.forEach((feature) => {
          if (feature && feature.isEnabledForSlot(pageId, containerId, slotId)) {
            newSlot = {
              ...newSlot,
              ...feature.transform(pageId, containerId, slotId),
            };
          }
        });

        State.setSlot(containerId, newSlot as Slot);
        setContainerObserver(containerId, isLazyLoaded);
        Features.forEach((feature) => {
          if (feature && feature.isEnabledForSlot(pageId, containerId, slotId)) {
            feature.addContainerObservers(containerId);
          }
        });

        await create(containerId, slotId, params);
      }

      if (Style) {
        if (existingSlot) {
          console.debug(
            `[SLOTS][SLOT][SET] Style.removeClass`,
            existingSlot.pageId,
            existingSlot.containerId,
            existingSlot.id
          );
          Style.removeClass(existingSlot.pageId, existingSlot.containerId, existingSlot.id);
        }
        console.debug(`[SLOTS][SLOT][SET] Style.addClass`, pageId, containerId, slotId);
        Style.addClass(pageId, containerId, slotId);
      }
    } else {
      console.error(`[SLOTS][SLOT][SET] ${containerId} PROVIDER MODULE NOT FOUND`);
      newRelicMetrics.reportError(NewRelicError.SLOT_PROVIDER_MODULE_FOR_TRANSFORM_NOT_FOUND, {
        pageId,
        containerId,
        slotId,
        params,
      });
    }
  } else if (!container && containerId && existingSlot) {
    console.debug(`[SLOTS][SLOT][SET][!container && containerId && existingSlot] remove `, containerId);
    await remove(containerId);
  } else if (!container) {
    console.error(`[SLOTS][SLOT][SET] ${containerId} CONTAINER NOT FOUND`);
    newRelicMetrics.reportError(NewRelicError.SLOT_CONTAINER_DIV_NOT_FOUND, { pageId, containerId, slotId, params });
  } else {
    console.error('[SLOTS][SLOT][SET] Html.hideElement', getInnerId(containerId));
    newRelicMetrics.reportError(NewRelicError.SLOT_NO_PROVIDER, { pageId, containerId, slotId, params });
    Html.hideElement(getInnerId(containerId));
  }
  console.debug(
    `[SLOTS][SLOT][SET][END] container`,
    containerId,
    Html.getElementById(containerId),
    Html.getElementById(getInnerId(containerId))
  );
};

export const getLoadable = () => {
  console.info('[SLOTS][SLOT][GETLOADABLE]');
  const slots = State.getSlots();
  console.debug('[SLOTS][SLOT][GETLOADABLE] Object.keys', slots);
  return Object.keys(slots)
    .filter((containerId) => {
      const unloaded = !slots[containerId]?.status?.loaded;
      const created = slots[containerId]?.status?.created;
      const elementExists = Html.getElementById(slots[containerId]?.innerId);
      console.debug(
        '[SLOTS][SLOT][GETLOADABLE] filter containerId',
        containerId,
        'unloaded',
        unloaded,
        'created',
        created,
        'elementExists',
        elementExists,
        'innerId',
        slots[containerId]?.innerId
      );
      return elementExists && created && unloaded;
    })
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = slots[key];
      return obj;
    }, {});
};

export const getUnloaded = () => {
  console.info('[SLOTS][SLOT][GETUNLOADED]');
  const slots = State.getSlots();
  console.debug('[SLOTS][SLOT][GETUNLOADED] Object.keys', slots);
  return Object.keys(slots).filter((containerId) => !slots[containerId]?.status?.loaded);
};
